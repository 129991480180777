import {
  type BaseParams,
  type BaseBody,
  type BannerContext,
  type ProductContext,
} from './model';

interface CreateBaseBodyProps extends BaseParams {
  context: BannerContext | ProductContext;
}

export const createBaseBody = (props: CreateBaseBodyProps): BaseBody => {
  const { context, sessionId, userId } = props;

  return {
    context: context.name,
    session_id: sessionId,
    product_sku: 'productId' in context ? String(context.productId) : undefined,
    category_name: 'categoryName' in context ? context.categoryName : undefined,
    term: 'term' in context ? context.term : undefined,
    user_id: userId,
  };
};
