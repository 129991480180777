import { type HttpClient } from '@/application/interfaces/http-client';

import { getBreadcrumb } from './get-breadcrumb';
import { getDetails } from './get-details';
import { getHeaderMenu } from './get-header-menu';
import { getMetadata } from './get-metadata';
import getSubcategoryChildren from './get-subcategory-children';

const CategoriesService = (httpClient: HttpClient.Instance) => ({
  getBreadcrumb: getBreadcrumb(httpClient),
  getDetails: getDetails(httpClient),
  getHeaderMenu: getHeaderMenu(httpClient),
  getMetadata: getMetadata(httpClient),
  getSubcategoryChildren: getSubcategoryChildren(httpClient),
});

export default CategoriesService;
