import { type HttpClient } from '@/application/interfaces/http-client';

import { getMetadata } from './get-metadata';
import { getModules } from './get-modules';
import { getRecommendedProducts } from './get-recommended-products';
import { getRecommendedProductsPlacements } from './get-recommended-products-placements';
import getVouchersData from './get-vouchers-data/get-vouchers-data';

const ModularContentService = (httpClient: HttpClient.Instance) => ({
  getMetadata: getMetadata(httpClient),
  getModules: getModules(httpClient),
  getRecommendedProducts: getRecommendedProducts(httpClient),
  getRecommendedProductsPlacements:
    getRecommendedProductsPlacements(httpClient),
  getVouchersData: getVouchersData(httpClient),
});

export default ModularContentService;
