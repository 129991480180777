import { type ServiceMethod } from '@/application/interfaces/service';

import {
  type BannerContext,
  type BaseBody,
  type BaseParams,
  type Size,
} from '../model';
import { createBaseBody } from '../utils';

import { type BannerAds } from './model';

export type Params = BaseParams & {
  context: BannerContext;
};

export type Body = BaseBody & {
  placements: {
    fullBanner: {
      quantity: number;
      types: ['banner'];
      size: Size;
    };
  };
};

export type Response = {
  fullBanner: BannerAds[];
};

const baseUrl = process.env.NEXT_PUBLIC_RETAIL_MEDIA_BASE_URL;
const publisherId = process.env.NEXT_PUBLIC_RETAIL_MEDIA_PUBLISHER_ID;

const getBannersAds: ServiceMethod<Response, Params> =
  (httpClient) => async (params, options) => {
    const { context, sessionId, userId } = params;

    const { body: response } = await httpClient.post<Response, Body>(
      `${baseUrl}/v1/rma/${publisherId}`,
      {
        ...options,
        body: {
          ...createBaseBody({ context, sessionId, userId }),
          placements: {
            fullBanner: {
              quantity: 1,
              size: context.size,
              types: ['banner'],
            },
          },
        },
      }
    );

    return response;
  };

export default getBannersAds;
