import { type ServiceMethod } from '@/application/interfaces/service';
import { type ItemId } from '@/infra/services/catalog/model';

import { type Store } from '../model';

export type Params = {
  itemId: ItemId;
};

export type Response = {
  data: {
    ecommerce: {
      isAvailable: boolean;
      stock: number;
      message: string;
    };
    lastUpdate: string | null;
    lm: number;
    packagingQuantity: number;
    stores: Store[];
    un: string;
  };
};

const getStocks: ServiceMethod<Response, Params> =
  (httpClient) => async (data, options) => {
    const { itemId } = data;

    const { body: response } = await httpClient.get<Response, Params>(
      '/api/v3/products/{itemId}/store-stock',
      {
        ...options,
        params: {
          itemId,
        },
      }
    );

    return response;
  };

export default getStocks;
