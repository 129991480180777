import { type HttpClient } from '@/application/interfaces/http-client';

import { getBannersAds } from './get-banners-ads';
import { getProductsAds } from './get-products-ads';

const NewTailService = (httpClient: HttpClient.Instance) => ({
  getBannersAds: getBannersAds(httpClient),
  getProductsAds: getProductsAds(httpClient),
});

export default NewTailService;
