import { type ServiceMethod } from '@/application/interfaces/service';

type HeaderItem = {
  name: string;
  id?: string;
  priority: number;
  type: string;
  url?: string;
  hasChildren: boolean;
};

export type HeaderMenu = HeaderItem & {
  items?: HeaderItem[];
};

export type Response = {
  data: HeaderMenu[];
};

const getHeaderMenu: ServiceMethod<Response> = (httpClient) => async () => {
  const { body } = await httpClient.get<Response, undefined, undefined>(
    '/api/v3/header-menu'
  );

  return body;
};

export default getHeaderMenu;
