import { type ServiceMethod } from '@/application/interfaces/service';

import { type ModularContent } from '../model';

export type Body = {
  userId?: string;
  products: Array<{
    id: number;
    trackingUrl?: string;
  }>;
};

export type Response = {
  data: ModularContent.Product[];
};

const getRecommendedProducts: ServiceMethod<Response, Body> =
  (httpClient) => async (data, options) => {
    const { body } = await httpClient.post<
      Response,
      Body,
      undefined,
      undefined
    >('/api/v3/products/recommended', {
      ...options,
      body: data,
    });

    return body;
  };

export default getRecommendedProducts;
