import { type ServiceMethod } from '@/application/interfaces/service';

import { type ProductContext, type BaseBody, type BaseParams } from '../model';
import { createBaseBody } from '../utils';

import { type ProductAds } from './model';

export type Params = BaseParams & {
  context: ProductContext;
};

export type Body = BaseBody & {
  placements: {
    productsAds: { quantity: number; types: ['product'] };
  };
};

export type Response = {
  productsAds: ProductAds[];
};

const baseUrl = process.env.NEXT_PUBLIC_RETAIL_MEDIA_BASE_URL;
const publisherId = process.env.NEXT_PUBLIC_RETAIL_MEDIA_PUBLISHER_ID;

const getProductsAds: ServiceMethod<Response, Params> =
  (httpClient) => async (params, options) => {
    const { context, sessionId, userId } = params;

    const { body: response } = await httpClient.post<Response, Body>(
      `${baseUrl}/v1/rma/${publisherId}`,
      {
        ...options,
        body: {
          ...createBaseBody({ context, sessionId, userId }),
          placements: {
            productsAds: { quantity: 10, types: ['product'] },
          },
        },
      }
    );

    return response;
  };

export default getProductsAds;
